import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/service';

@Component({
	selector: 'app-connect-error',
	templateUrl: './connect-error.component.html',
	styleUrls: ['./connect-error.component.scss']
})
export class ConnectErrorComponent implements OnInit {

	constructor(private service: Service) { }

	ngOnInit(): void {
	}

	public retry(): void {
		this.service.result = null;
		this.service.request();
	}
}