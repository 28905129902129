export enum ResultsEnum {
	//INITIALIZING = "INITIALIZING",
	NOT_ACTIVATED = "NOT_ACTIVATED",
	ACTIVATED = "ACTIVATED",
	NOT_PLAYING = "NOT_PLAYING",
	LINK_INVALID_OR_EXPIRED = "LINK_INVALID_OR_EXPIRED",
	LINK_ALREADY_VISITED = "LINK_ALREADY_VISITED",
	REDIRECT = "REDIRECT",
	TEMPORARY_DISABLED = "TEMPORARY_DISABLED",
	PROXY_VPN_DETECTED = "proxy_vpn_detected",
}