<div class="d-flex flex-column align-items-center">

	<h3 class="m-3 text-purple">Kiss Kiss : Spin the Bottle : the game</h3>

	<h4 class="m-3">Unable to connect to server, please try again later!</h4>

	<div class="m-3" style="min-height: 100px;">

		<button class="splash--start-button" (click)="retry()">Retry<span class="icon-play"></span></button>

	</div>

</div>