import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-how-to-activate-modal',
	templateUrl: './how-to-activate-modal.component.html',
	styleUrls: ['./how-to-activate-modal.component.scss']
})
export class HowToActivateModalComponent implements OnInit {

	constructor(private modalService: BsModalService) { }

	ngOnInit(): void { }

	public close(): void {
		this.modalService.hide();
	}

}