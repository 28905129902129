<div class="text-center">

	<h3 class="m-4">Thank you for visiting our advertisements!</h3>

	<h3 class="m-4">Your game has been successfully activated! Now you can enjoy it!</h3>

	<h4 class="m-3">I will improve that game to make it more helpful and useful.</h4>

	<h4 class="m-3">You can close this window now!</h4>

</div>