import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-redirect',
	templateUrl: './redirect.component.html',
	styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

	constructor(private spinner: NgxSpinnerService) { }

	ngOnInit(): void {
		this.spinner.show("spinner");
	}

}