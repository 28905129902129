import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Service } from 'src/app/service/service';

@Component({
	selector: 'app-temporary-disabled',
	templateUrl: './temporary-disabled.component.html',
	styleUrls: ['./temporary-disabled.component.scss']
})
export class TemporaryDisabledComponent implements OnInit {

	constructor(private titleService: Title, private service: Service) {
		this.titleService.setTitle("Service Unavailable");
	}

	ngOnInit(): void {
	}

	public retry(): void {
		this.service.result = null;
		this.service.request();
	}
}