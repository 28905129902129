import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-activated',
	templateUrl: './activated.component.html',
	styleUrls: ['./activated.component.scss']
})
export class ActivatedComponent implements OnInit {

	constructor(private titleService: Title) {
		this.titleService.setTitle("Thank you!");
	}

	ngOnInit(): void { }
}