import { Component, OnInit, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Service } from './service/service';
import { ResultsEnum } from './enums/results.enum';
import { DOCUMENT } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DownloadAppsForAndroidModalComponent } from './modals/download-apps-for-android-modal/download-apps-for-android-modal.component';
import { LocalStorageService } from '@shaaakh/ngx-webstorage';
import { StatusEnum } from './enums/status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	public ResultEnum = ResultsEnum;
	public StatusEnum = StatusEnum;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private spinner: NgxSpinnerService,
		public service: Service,
		private modalService: BsModalService,
		private local: LocalStorageService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private ccService: NgcCookieConsentService /*** Notice : DO NOT REMOVE THIS LINE ***/
	) {

		const parameters = new URLSearchParams(window.location.search);

		let redirect: string = parameters.get("redirect");

		if (redirect) {
			this.removeParametersFromURL();
			this.service.status = null;
			this.service.result = ResultsEnum.REDIRECT;
			this.spinner.hide("loading");
			try {
				let link: string = atob(redirect);
				let url = new URL(link);
				if (this.document.location.host !== url.host) {
					this.document.location.href = link;
				} else {
					console.log("link invalid :", link);
				}
			} catch (error) {
				console.log("link error :", error);
			}
			return;
		}

		this.service.getResult().subscribe((value: ResultsEnum) => {
			switch (value) {
				case ResultsEnum.NOT_PLAYING:
					this.open_popup();
					break;
				case ResultsEnum.ACTIVATED:
					this.open_popup();
					break;
			}
		});

		this.service.getStatus().subscribe((value: StatusEnum) => {
			switch (value) {
				case null:
					this.spinner.hide("loading");
					break;
				case StatusEnum.LOADING:
					if (this.service.result === null) {
						this.spinner.show("loading");
					}
					break;
				case StatusEnum.ERROR:
					this.spinner.hide("loading");
					break;
				case StatusEnum.CONNECT_ERROR:
					this.spinner.hide("loading");
					break;
			}
		});

		switch (this.document.location.search) {
			case "":
				this.service.request();
				break;
			case "?action=activated":
				this.service.status = null;
				this.service.result = ResultsEnum.ACTIVATED;
				this.removeParametersFromURL();
				break;
			case "?action=not-activated":
				this.service.status = null;
				this.service.result = ResultsEnum.NOT_ACTIVATED;
				this.removeParametersFromURL();
				break;
			case "?action=not-playing":
				this.service.status = null;
				this.service.result = ResultsEnum.NOT_PLAYING;
				this.removeParametersFromURL();
				break;
			case "?action=link-already-visited":
				this.service.status = null;
				this.service.result = ResultsEnum.LINK_ALREADY_VISITED;
				this.removeParametersFromURL();
				break;
			case "?action=link-invalid-or-expired":
				this.service.status = null;
				this.service.result = ResultsEnum.LINK_INVALID_OR_EXPIRED;
				this.removeParametersFromURL();
				break;
			default:
				this.service.request();
		}
	}

	ngOnInit(): void { }

	public retry(): void {
		this.service.request();
	}

	public open_popup(): void {
		if (this.service.popup === true && this.local.get('dont_show_download_app') === null) {
			this.service.popup = false;
			this.modalService.show(DownloadAppsForAndroidModalComponent, { class: 'modal-lg' });
		}
	}

	//https://stackoverflow.com/questions/35618463/change-route-params-without-reloading-in-angular-2
	public removeParametersFromURL(): void {
		var url = this.router.createUrlTree([], { relativeTo: this.activatedRoute }).toString();
		this.router.navigateByUrl(url);

	}
}