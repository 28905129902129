import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { FooterComponent } from './footer/footer.component';
import { Service } from './service/service';
import { NotActivatedComponent } from './not-activated/not-activated.component';
import { ActivatedComponent } from './components/activated/activated.component';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { HowToActivateModalComponent } from './modals/how-to-activate-modal/how-to-activate-modal.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { DownloadAppsForAndroidModalComponent } from './modals/download-apps-for-android-modal/download-apps-for-android-modal.component';
import { AngularWebStorageModule } from '@shaaakh/ngx-webstorage';
import { NotPlayingComponent } from './components/not-playing/not-playing.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ConnectErrorComponent } from './components/connect-error/connect-error.component';
import { TemporaryDisabledComponent } from './components/temporary-disabled/temporary-disabled.component';
import { ProxyVpnDetectedComponent } from './components/proxy-vpn-detected/proxy-vpn-detected.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: "kisskiss.herokuapp.com"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#ff3342",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#ffffff",
      "text": "#ff3342",
      "border": "transparent"
    }
  },
  "type": "info",
};

@NgModule({
  declarations: [
    AppComponent,
    ActivatedComponent,
    FooterComponent,
    NotActivatedComponent,
    RedirectComponent,
    HowToActivateModalComponent,
    DownloadAppsForAndroidModalComponent,
    NotPlayingComponent,
    ConnectErrorComponent,
    TemporaryDisabledComponent,
    ProxyVpnDetectedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    RouterModule.forRoot([]),
    AngularWebStorageModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    Service,
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
