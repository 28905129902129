import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Service } from 'src/app/service/service';

@Component({
	selector: 'app-not-playing',
	templateUrl: './not-playing.component.html',
	styleUrls: ['./not-playing.component.scss']
})
export class NotPlayingComponent implements OnInit {

	constructor(private titleService: Title, public service: Service) {
		this.titleService.setTitle("You are not playing!");
	}

	ngOnInit(): void { }

	public retry(): void {
		this.service.result = null;
		this.service.request();
	}

	public downloadnow(): void {
		window.location.href = this.service.download_link.link;
	}
}