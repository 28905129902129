import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from '@shaaakh/ngx-webstorage';

@Component({
	selector: 'app-download-apps-for-android-modal',
	templateUrl: './download-apps-for-android-modal.component.html',
	styleUrls: ['./download-apps-for-android-modal.component.scss']
})
export class DownloadAppsForAndroidModalComponent implements OnInit {

	public google_play_url: string = "https://play.google.com/store/apps/details?id=com.topface.kisskiss&hl=en&gl=US";

	constructor(private modalService: BsModalService, private local: LocalStorageService) { }

	ngOnInit(): void { }

	public close(): void {
		this.modalService.hide()
	}

	public onCheckboxChanged(event: Event): void {
		let input = event.target as HTMLInputElement;
		if (input.checked) {
			this.local.set("dont_show_download_app", true, 30, "d");
		} else {
			this.local.remove("dont_show_download_app");
		}
	}

	public openGoogleStore(): void {
		window.open(this.google_play_url);
	}
}
