<!--
<div class="p-1" style="position: fixed; left: 10px; top: 10px; text-align: left; background-color: rgba(255, 255, 0, 0.5);">
	<pre>Status : {{ service.status | json }}</pre>
	<pre>Result : {{ service.result | json }}</pre>
</div>
-->

<div class="h-100 d-flex flex-column">

	<div class="flex-fill">

		<ng-container [ngSwitch]="service.result">

			<ng-container *ngSwitchCase="null">

				<div *ngIf="service.status === StatusEnum.ERROR || service.status === StatusEnum.CONNECT_ERROR">
					<app-connect-error></app-connect-error>
				</div>

				<div *ngIf="service.status === StatusEnum.LOADING"></div>

			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.NOT_PLAYING">
				<app-not-playing></app-not-playing>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.ACTIVATED">
				<app-activated></app-activated>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.NOT_ACTIVATED">
				<app-not-activated></app-not-activated>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.LINK_ALREADY_VISITED">
				<app-not-activated></app-not-activated>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.LINK_INVALID_OR_EXPIRED">
				<app-not-activated></app-not-activated>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.REDIRECT">
				<app-redirect></app-redirect>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.TEMPORARY_DISABLED">
				<app-temporary-disabled></app-temporary-disabled>
			</ng-container>

			<ng-container *ngSwitchCase="ResultEnum.PROXY_VPN_DETECTED">
				<app-proxy-vpn-detected></app-proxy-vpn-detected>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<!-- <span>Unknown result : {{ service.result | json }}</span> -->
			</ng-container>

		</ng-container>

	</div>

	<app-footer *ngIf="service.result !== null || service.status !== StatusEnum.LOADING"></app-footer>

</div>

<ngx-spinner name="loading" bdColor="rgba(0, 0, 0, 0.5)" size="large" color="#ff3768" type="ball-spin-clockwise" [fullScreen]="true">
	<p class="m-3" style="color: white">Loading...</p>
</ngx-spinner>