<div class="modal-header">

	<h5 class="modal-title pull-left">The app is available for Android!</h5>
	<button type="button" class="close pull-right" data-dismiss="modal" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>

</div>

<div class="modal-body">

	<p style="font-weight: 500;">Kiss Kiss: spin the bottle is your new chance to fall in love and find amazing friends. Join one of the best bottle spin games!</p>

	<div style="height: 400px; background-color: black;">

		<iframe width="100%" height="400" src="https://www.youtube-nocookie.com/embed/kRqrLmWxK0I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

	</div>

</div>

<div class="modal-footer">

	<div class="custom-control custom-checkbox mr-auto">
		<input type="checkbox" class="custom-control-input" id="customCheck1" (change)="onCheckboxChanged($event)">
		<label class="custom-control-label" for="customCheck1">Don't show again</label>
	</div>

	<button type="button" class="btn btn-sm btn-secondary w100" data-dismiss="modal" (click)="close()">Close</button>

	<button type="button" class="btn btn-sm btn-primary w100 d-flex align-self-center align-items-center" (click)="openGoogleStore()">
		<div class="google-play-store-icon"></div>
		<span>Download App Now!</span>
	</button>

</div>