import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Service } from '../service/service';
import { Title } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HowToActivateModalComponent } from '../modals/how-to-activate-modal/how-to-activate-modal.component';
import { StatusEnum } from '../enums/status.enum';
import { ResultsEnum } from '../enums/results.enum';

@Component({
	selector: 'app-not-activated',
	templateUrl: './not-activated.component.html',
	styleUrls: ['./not-activated.component.scss']
})
export class NotActivatedComponent implements OnInit {

	public StatusEnum = StatusEnum;
	public ResultsEnum = ResultsEnum;

	public message: string;

	constructor(
		private spinner: NgxSpinnerService,
		public service: Service,
		private titleService: Title,
		private modalService: BsModalService) {

		this.service.getStatus().subscribe((value: StatusEnum) => {
			if (value === StatusEnum.LOADING) {
				this.spinner.show("spinner");
			} else {
				this.spinner.hide("spinner");
			}
		});
	}

	ngOnInit(): void {

		switch (this.service.result) {
			case ResultsEnum.NOT_ACTIVATED:
				this.titleService.setTitle("Go and activate the game!");
				this.message = "Please complete it then your game application will be activated!";
				break;
			case ResultsEnum.LINK_ALREADY_VISITED:
				this.titleService.setTitle("The link that you have already visited!");
				this.message = "We're sorry, it looks like you've already visited the link before!";
				break;
			case ResultsEnum.LINK_INVALID_OR_EXPIRED:
				this.titleService.setTitle("The link is invalid or expired!");
				this.message = "We're sorry, it seems like the link you've visited is invalid or expired!";
				break;
		}
	}

	public request(value: boolean): void {
		this.service.request(value);
	}

	public showHowToActivate(): void {
		this.modalService.show(HowToActivateModalComponent, { class: 'modal-lg' });
	}

}