<div class="text-center">

	<!-- https://www.ipqualityscore.com/disable-your-proxy-vpn-connection -->

	<h3 class="m-3 row align-items-center justify-content-center">
		<div class="warning-icon"></div>
		<div class="m-2">Please Disable Your Proxy or VPN Connection</div>
		<div class="warning-icon"></div>
	</h3>

	<h4 class="m-4">We detected an issue indicating your internet connection may be using a Proxy or VPN.</h4>

	<h4 class="m-4">Your IP address is : <strong>{{service.ip_address}}</strong></h4>

	<h4 class="m-4">Once you have disabled your Proxy or VPN connection, please refresh the page.</h4>

	<h4 class="m-4">If you believe this is a false-positive, please <a href="https://www.facebook.com/KissKissBot/" target="_blank">contact us through the inbox on Facebook</a>. Include your IP address in the message : {{service.ip_address}}.</h4>

	<h5 class="m-3">Not using a proxy? Please disable any plugins or services that could be masking your online identity.</h5>

</div>