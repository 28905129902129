<div class="modal-header">

	<h5 class="modal-title">How to activate the game</h5>
	<button type="button" class="close" data-dismiss="modal" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>

</div>

<div class="modal-body">

	<div style="width:100%;height:0px;position:relative;padding-bottom:80.000%;background-color:gainsboro;">
		<iframe src="https://streamable.com/e/wujvbd?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe>
	</div>

</div>

<div class="modal-footer">

	<button type="button" class="btn btn-sm btn-secondary w100" data-dismiss="modal" (click)="close()">Close</button>

</div>