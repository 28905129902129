<div class="text-center">

	<h4 class="m-3 text-purple">You will be automaticaly redirected in few seconds...</h4>

	<div class="d-flex align-items-center justify-content-center m-3" style="min-height: 100px; position: relative;">

		<ngx-spinner name="spinner" bdColor="rgba(0, 0, 0, 0.0)" size="medium" color="#ff3768" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>

	</div>

</div>