import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/service';

@Component({
	selector: 'app-proxy-vpn-detected',
	templateUrl: './proxy-vpn-detected.component.html',
	styleUrls: ['./proxy-vpn-detected.component.scss']
})
export class ProxyVpnDetectedComponent implements OnInit {

	constructor(public service: Service) { }

	ngOnInit(): void { }

}
