<div class="text-center">

	<h3 class="m-3 text-purple">Kiss Kiss : Spin the Bottle : the game</h3>

	<h3 class="m-3">It seems that you are not playing the game.</h3>

	<h4 class="m-3">Please open the game first and then click bellow to retry!</h4>

	<div class="d-flex align-items-center justify-content-center m-3" style="min-height: 120px; position: relative;">

		<button class="button splash--start-button" (click)="retry()">Retry<span class="icon-play"></span></button>

		<ngx-spinner name="spinner" bdColor="rgba(0, 0, 0, 0.0)" size="medium" color="#ff3768" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>

	</div>

	<hr class="m-4">

	<ng-template [ngIf]="service.download_link?.link">

		<h4 class="m-3 row align-items-center justify-content-center">
			<span>The game application for Windows</span>
			<div class="windows-logo"></div>
			<span>is available for download</span>
		</h4>

		<button style="width: fit-content; padding-left: 20px; padding-right: 20px;" class="button splash--start-button m-3" (click)="downloadnow()">Download Now<span class="icon-play"></span></button>

		<hr class="m-4">

	</ng-template>

	<h3 class="text-purple">The official of the game on Facebook</h3>

	<h4 class="pt-3 text-purple">In the meantime, you can play it on Facebook now!</h4>

	<div class="m-5">
		<a href="https://www.facebook.com/ksskssmeowpublic" target="_blank">
			<button class="button splash--start-button">Play Now<span class="icon-play"></span></button>
		</a>
	</div>

</div>