<div class="text-center">

	<h3 class="m-3 text-purple">Kiss Kiss : Spin the Bottle : the game</h3>

	<h4 class="m-3">{{ message }}</h4>

	<h5 class="m-3">Please click the "GO" button below to activate your game!</h5>

	<div class="d-flex align-items-center justify-content-center m-3" style="min-height: 100px; position: relative;">

		<ng-template [ngIf]="service.status === null">
			<button class="splash--start-button" (click)="request(true)">GO<span class="icon-play"></span></button>
		</ng-template>

		<ng-template [ngIf]="service.status === StatusEnum.LOADING">
			<ngx-spinner name="spinner" bdColor="rgba(0, 0, 0, 0.0)" size="medium" color="#ff3768" type="ball-spin-clockwise" [fullScreen]="false"></ngx-spinner>
		</ng-template>

		<ng-template [ngIf]="service.status === StatusEnum.ERROR || service.status === StatusEnum.CONNECT_ERROR">
			<button class="splash--start-button" (click)="request(true)">Retry<span class="icon-play"></span></button>
		</ng-template>

		<!--
		<ng-container [ngSwitch]="service.status">
			<ng-container *ngSwitchCase="null">
			</ng-container>
			<ng-container *ngSwitchCase="StatusEnum.LOADING">
			</ng-container>
			<ng-container *ngSwitchCase="StatusEnum.ERROR">
			</ng-container>
			<ng-container *ngSwitchDefault>
			</ng-container>
		</ng-container>
		-->

	</div>

	<div class="m-5">

		<h5 class="m-3">If you wanna know how to activate the game? Click the button below to watch it!</h5>

		<button class="splash--start-button m-3" style="width: 300px;" (click)="showHowToActivate()">How to activate<span class="icon-play"></span></button>

	</div>

	<hr class="m-4">

	<h3 class="m-3 text-purple">The official of the game on Facebook</h3>
	<h4 class="m-3 text-purple">In the meantime, you can play it on Facebook now!</h4>

	<div class="m-5">
		<a href="https://www.facebook.com/ksskssmeowpublic/" target="_blank">
			<button class="splash--start-button">Play Now<span class="icon-play"></span></button>
		</a>
	</div>

</div>