import { Injectable, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response.model';
import { ResultsEnum } from '../enums/results.enum';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusEnum } from '../enums/status.enum';
import { RequestModel } from '../models/request.model';
import { DownloadLink } from '../models/download-link.model';

@Injectable()
export class Service {

	private result_dispatcher: EventEmitter<ResultsEnum> = new EventEmitter();
	private status_dispatcher: EventEmitter<StatusEnum> = new EventEmitter();

	private _result: ResultsEnum = null;
	private _status: StatusEnum = StatusEnum.LOADING;

	public popup: boolean = true;

	public ip_address: string = null;

	public download_link: DownloadLink = null;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private client: HttpClient,
		private route: ActivatedRoute,
		private router: Router) { }

	public getStatus(): EventEmitter<StatusEnum> {
		return this.status_dispatcher;
	}

	public getResult(): EventEmitter<ResultsEnum> {
		return this.result_dispatcher;
	}

	public set status(value: StatusEnum) {
		this._status = value;
		this.status_dispatcher.emit(value);
	}

	public get status(): StatusEnum {
		return this._status;
	}

	public set result(value: ResultsEnum) {
		this._result = value;
		this.result_dispatcher.emit(value);
	}

	public get result(): ResultsEnum {
		return this._result;
	}

	public request(request_go: boolean = false): void {

		this.status = StatusEnum.LOADING;

		let request: RequestModel = {
			request: request_go
		};

		const params = new URLSearchParams(window.location.search);
		if (params.has("service")) {
			request.service_id = params.get("service")
		}

		//this.client.post(environment.BASE_URL + "api.php", request)
		this.client.post("/api.php", request)
			.subscribe(
				(response: ResponseModel) => {

					if (response && response.download_link && response.download_link.link) {
						this.download_link = response.download_link;
					}
					else {
						this.download_link = null;
					}

					if (response && response.result) {

						switch (response.result) {

							case ResultsEnum.NOT_PLAYING:
								this.status = null;
								this.result = ResultsEnum.NOT_PLAYING;
								break;

							case ResultsEnum.NOT_ACTIVATED:

								if (this.result === ResultsEnum.LINK_ALREADY_VISITED || this.result === ResultsEnum.LINK_INVALID_OR_EXPIRED) {
									//nothing, keep it
								} else {
									this.result = ResultsEnum.NOT_ACTIVATED;
								}

								if (request_go) {
									if (response.link) {
										this.document.location.href = response.link;
									}
									else {
										this.status = StatusEnum.ERROR;
									}
								} else {
									this.status = null;
								}
								break;

							case ResultsEnum.ACTIVATED:
								this.result = ResultsEnum.ACTIVATED;
								this.status = null;
								this.changeURL();
								break;

							case ResultsEnum.TEMPORARY_DISABLED:
								this.result = ResultsEnum.TEMPORARY_DISABLED;
								this.status = null;
								break;

							case ResultsEnum.PROXY_VPN_DETECTED:
								this.result = ResultsEnum.PROXY_VPN_DETECTED;
								this.status = null;
								this.ip_address = response.ip_address;
								break;

							default:
								this.status = StatusEnum.ERROR;
						}
					}
					else {
						this.status = StatusEnum.ERROR;
					}
				},
				(error: HttpErrorResponse) => {
					this.status = StatusEnum.CONNECT_ERROR;
				}
			);
	}

	private changeURL(): void {

		if (this.route.snapshot.queryParamMap.has("action")) {

			switch (this.result) {

				case ResultsEnum.ACTIVATED:
					if (this.route.snapshot.queryParamMap.get("action") !== "activated") {
						this.router.navigate(["/"], { queryParams: { action: "activated" } });
					}
					break;
			}
		}
	}
}