<div class="text-center">

	<h3 class="m-3">Service Unavailable</h3>

	<h3 class="m-3">Server currently undergoing maintenance.</h3>

	<!-- <h4>Please try again in a few moments.</h4> -->
	<!-- Scheduled server upgraded. We will be back shortly. -->

	<h4 class="m-3">Please wait for 30 minutes and try again.</h4>

	<h4 class="m-3">Thank you for your understanding!</h4>

	<div class="d-flex align-items-center justify-content-center m-3" style="min-height: 120px; position: relative;">

		<button class="button splash--start-button" (click)="retry()">Retry<span class="icon-play"></span></button>

	</div>

</div>